@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;500&display=swap');
@import 'react-tooltip/dist/react-tooltip.css';

body {
  margin: 0;
  font-family: 'Josefin Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.shadow-common {
  box-shadow: 10px 8px 12px -1px rgba(0, 0, 0, 0.1), 10px 4px 8px -1px rgba(0, 0, 0, 0.06);
}

.shadow-uncommon {
  box-shadow: 10px 8px 12px -1px rgba(59, 130, 246, 0.5), 10px 4px 8px -1px rgba(59, 130, 246, 0.25);
}

.shadow-rare {
  box-shadow: 10px 8px 12px -1px rgba(234, 179, 8, 0.5), 10px 4px 8px -1px rgba(216, 206, 149, 0.5);
}
.rainbow-text:hover{
  background: linear-gradient(66deg, red, orange, gold, green, blue, indigo, violet);
  color: black;
}

.rainbow-text {
  background: linear-gradient(120deg, red, orange, gold, green, blue, indigo, violet);
  -webkit-background-clip: text;
  color: transparent;
}

.parchment-container {
  position: relative;
  text-align: center;
  background: white;
  padding: 0px;
  margin-top: 20px;
}

.parchment-container::before {
  content: "   ______________________________\A / \\                             \\.\A|   |                            |.\A \\_ |                            |.\A    |                            |.\A    |                            |.\A    |                            |.\A    |                            |.\A    |                            |.\A    |                            |.\A    |                            |.\A    |                            |.\A    |                            |.\A    |                            |.\A    |   _________________________|___\A    |  /                            /.\A    \\_/____________________________/.";
  white-space: pre;
  font-family: monospace;
  font: bold;
  font-style: oblique 40deg;;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  line-height: 1.2;
  color: #8b4513;
  z-index: -1;
}

.title {
  position: relative;
  z-index: 1;
}
